section#how {
  // 0px creates a white gap?
  padding-bottom: 1px;

  .cards {
    position: relative;
    display: inline-block;
    margin: 3rem auto 0;

    @include desktop {
      margin-bottom: 3rem;
    }

    img {
      width: 18rem;
      max-width: 49%;
      transition: all 0.4s ease-in-out;
    }

    @include desktop {
      margin: 6rem auto;

      &.closed img {
        &:nth-child(1) {
          transform: rotate(0) translateX(150%);
        }

        &:nth-child(2) {
          transform: rotate(-1.25deg) translateX(50%);
        }

        &:nth-child(3) {
          transform: rotate(1.25deg) translateX(-50%);
        }

        &:nth-child(4) {
          transform: rotate(-1.25deg) translateX(-155%);
        }
      }

      img {
        position: relative;
        width: 24rem;

        &:nth-child(1) {
          transform: rotate(-10deg) translateY(4.5rem) translateX(3.5rem);
          z-index: 4;
        }

        &:nth-child(2) {
          transform: rotate(-5deg) translateX(2rem);
          z-index: 3;
        }

        &:nth-child(3) {
          transform: rotate(5deg) translateX(-2rem);
          z-index: 2;
        }

        &:nth-child(4) {
          transform: rotate(10deg) translateY(5rem) translateX(-3.5rem);
          z-index: 1;
        }
      }
    }
  }
}