$animation: spread 0.4s ease-out;

@keyframes spread {
    from { top: -2rem; }
    to   { top: 0; }
}

.carousel {
  position: relative;
  display: inline-block;
  margin: 0 auto;

  .carousel-group {
    display: none;
    position: relative;

    img {
      position: relative;
      animation: $animation;

      &:nth-child(1) { animation-delay: 0s; }
      &:nth-child(2) { animation-delay: 0.05s; }
      &:nth-child(3) { animation-delay: 0.1s; }
      &:nth-child(4) { animation-delay: 0.15s; }
    }

    &.visible {
      display: block;
    }
  }

  /* For the white section */
  img.carousel-group {
    animation: $animation;
  }

  .carousel-arrow {
    display: inline-block;
    top: 50%;
    left: -3rem;
    font-size: 0;
    line-height: 0;
    transform: scale(0.7);

    @include desktop {
      position: absolute;
      display: inline-block;
      transform: scale(1);
    }

    &:before {
      content: url(../images/arrow.svg);
    }

    &:hover {
      opacity: 0.8;
    }

    &.carousel-arrow-next {
      left: auto;
      right: -3rem;
      transform: scale(0.7) rotate(180deg);

      @include desktop {
        transform: rotate(180deg);
      }
    }

    &.carousel-arrow-inverted:before {
      content: url(../images/arrow_inverted.svg);
    }
  }
}