.list {
  .item {
    margin-bottom: 3rem;
  }

  .header {
    margin-bottom: 1.5rem;
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: bold;
  }

  @include desktop {
    .content {
      padding: 0 1.5rem;
    }
  }
}