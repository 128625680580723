$width: 78rem;

form {
  label {
    display: block;
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  input[type=text],
  input[type=email] {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: $width;
    padding: 1.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    outline: none;
    border: 3px solid black;
    border-radius: 0;

    &:focus {
      background-color: $yellowLight;
    }
  }

  input[type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .radio {
    display: inline-block;
    position: relative;
    padding-left: 4rem;
    cursor: pointer;
    font-size: 2.8rem;
    margin-right: 3rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:last-of-type {
      margin-right: 0;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .mark:after {
        display: block;
      }
    }

    .mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 3rem;
      width: 3rem;
      background-color: white;
      border: 3px solid black;
      border-radius: 100%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 0.2rem;
        top: 0.2rem;
        width: 2rem;
        height: 2rem;
        background-color: black;
        border-radius: 100%;
      }
    }

    &:hover input ~ .mark {
      background-color: $yellowLight;
    }
  }

  .field {
    margin-bottom: 9rem;
  }

  .field-radio {
    margin-bottom: 6rem;
  }

  .field-cols {
    margin-left: auto;
    margin-right: auto;
    width: $width;
    max-width: 100%;

    @include desktop {
      display: flex;

      .field-col {
        flex-grow: 1;
        flex-basis: 0;
        margin-right: 3rem;

        &:last-of-type {
          margin-left: 3rem;
          margin-right: 0;
        }
      }
    }
  }

  .field-group {
    input[type=text] {
      margin-bottom: 3rem;

      @include desktop {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}