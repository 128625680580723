section#white-cards {
  .cards {
    margin: 3rem auto 0;

    @include desktop {
      margin: 6rem auto;
      padding: 0 3rem;
    }

    img {
      display: none;
      position: relative;
      width: 18rem;
      max-width: 49%;

      &:first-child,
      &.visible {
        display: inline;
      }

      @include desktop {
        width: 24rem;
        transform: rotate(5deg);

        &:nth-child(1) {
          transform: rotate(-5deg);
          z-index: 1;
        }
      }
    }
  }
}