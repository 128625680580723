body#thanks {
  background: black;

  h1 {
    font-size: 4rem;
    line-height: 4.7rem;

    @include desktop {
      font-size: 4.8rem;
      line-height: 5.6rem;
    }
  }

  @include desktop {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    > section {
      flex: 1;
    }
  }

  nav li:first-of-type {
    display: none;
  }
}