*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 55.55%;
  box-sizing: border-box;

  @include desktop {
    font-size: 62.5%;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  font-family: $font-sans;
  font-size: 1.8rem;
  line-height: 1.5em;
  background: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > div {
    flex: 1;
  }
}

h1, h2, h3, h4, h5 {
  line-height: 1.2em;
  margin-bottom: 3rem;
}

h1 {
  font-size: 4.8rem;
  line-height: 5.6rem;

  @include desktop {
    font-size: 6.2rem;
    line-height: 7.6rem;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 5.6rem;
  margin-bottom: 6rem;
}

p {
  margin: 3rem 0;
}

ul {
  list-style-position: inside;
}

img {
  max-width: 100%;
}

.show-desktop {
  display: none;

  @include desktop {
    display: block;
  }
}

section,
footer {
  padding: 6rem 3rem;
  background: white;

  @include desktop {
    padding: 9rem 3rem;
  }

  &.black {
    color: white;
    background: black;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    max-width: $content-width;
  }
}

.aligned {
  &.center {
    text-align: center;
  }
}

.good-pairing {
  max-width: 74rem;
  margin: 3rem auto;
  font-weight: bold;
  font-size: 2.4rem;

  em {
    font-style: normal;
    text-decoration: underline;
  }
}

