section#intro {
  @include desktop {
    display: flex;
    min-height: 100vh;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      flex: 1;
    }

    .learn-more {
      display: block !important;
    }
  }

  .learn-more {
    display: none;
    margin-top: 6rem;
    text-align: center;
    font-weight: bold;

    a {
      color: white;
      font-size: 2rem;
      text-decoration: none;
    }

    img {
      display: block;
      margin: 1rem auto 0;
      width: 6rem;
    }
  }
}