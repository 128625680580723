.button {
  display: inline-block;
  width: 28rem;
  max-width: 100%;
  padding: 1.5rem;
  line-height: 1em;
  color: black;
  background: white;
  font-weight: bold;
  font-size: 2.4rem;
  text-decoration: none;
  border: 3px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 0 black;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.button-fluid {
    width: 100%;
  }

  &.button-yellow {
    background-color: $yellow;
  }

  &.button-apply {
    border-color: $yellowDark;
    box-shadow: 0 4px 0 $yellowDark;
  }
}
