*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 55.55%;
  box-sizing: border-box;
}
@media (min-width: 63.9em) {
  html {
    font-size: 62.5%;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 1.5em;
  background: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body > div {
  flex: 1;
}

h1, h2, h3, h4, h5 {
  line-height: 1.2em;
  margin-bottom: 3rem;
}

h1 {
  font-size: 4.8rem;
  line-height: 5.6rem;
}
@media (min-width: 63.9em) {
  h1 {
    font-size: 6.2rem;
    line-height: 7.6rem;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 5.6rem;
  margin-bottom: 6rem;
}

p {
  margin: 3rem 0;
}

ul {
  list-style-position: inside;
}

img {
  max-width: 100%;
}

.show-desktop {
  display: none;
}
@media (min-width: 63.9em) {
  .show-desktop {
    display: block;
  }
}

section,
footer {
  padding: 6rem 3rem;
  background: white;
}
@media (min-width: 63.9em) {
  section,
footer {
    padding: 9rem 3rem;
  }
}
section.black,
footer.black {
  color: white;
  background: black;
}
section .container,
footer .container {
  margin: 0 auto;
  width: 100%;
  max-width: 81rem;
}

.aligned.center {
  text-align: center;
}

.good-pairing {
  max-width: 74rem;
  margin: 3rem auto;
  font-weight: bold;
  font-size: 2.4rem;
}
.good-pairing em {
  font-style: normal;
  text-decoration: underline;
}

.list .item {
  margin-bottom: 3rem;
}
.list .header {
  margin-bottom: 1.5rem;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: bold;
}
@media (min-width: 63.9em) {
  .list .content {
    padding: 0 1.5rem;
  }
}

.button {
  display: inline-block;
  width: 28rem;
  max-width: 100%;
  padding: 1.5rem;
  line-height: 1em;
  color: black;
  background: white;
  font-weight: bold;
  font-size: 2.4rem;
  text-decoration: none;
  border: 3px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 0 black;
  outline: none;
  cursor: pointer;
}
.button:hover {
  opacity: 0.8;
}
.button.button-fluid {
  width: 100%;
}
.button.button-yellow {
  background-color: #FFEC00;
}
.button.button-apply {
  border-color: #8E7E07;
  box-shadow: 0 4px 0 #8E7E07;
}

form label {
  display: block;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
form input[type=text],
form input[type=email] {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 78rem;
  padding: 1.5rem;
  font-size: 1.8rem;
  font-weight: bold;
  outline: none;
  border: 3px solid black;
  border-radius: 0;
}
form input[type=text]:focus,
form input[type=email]:focus {
  background-color: #fffde6;
}
form input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
form .radio {
  display: inline-block;
  position: relative;
  padding-left: 4rem;
  cursor: pointer;
  font-size: 2.8rem;
  margin-right: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
form .radio:last-of-type {
  margin-right: 0;
}
form .radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
form .radio input:checked ~ .mark:after {
  display: block;
}
form .radio .mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  width: 3rem;
  background-color: white;
  border: 3px solid black;
  border-radius: 100%;
}
form .radio .mark:after {
  content: "";
  position: absolute;
  display: none;
  left: 0.2rem;
  top: 0.2rem;
  width: 2rem;
  height: 2rem;
  background-color: black;
  border-radius: 100%;
}
form .radio:hover input ~ .mark {
  background-color: #fffde6;
}
form .field {
  margin-bottom: 9rem;
}
form .field-radio {
  margin-bottom: 6rem;
}
form .field-cols {
  margin-left: auto;
  margin-right: auto;
  width: 78rem;
  max-width: 100%;
}
@media (min-width: 63.9em) {
  form .field-cols {
    display: flex;
  }
  form .field-cols .field-col {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 3rem;
  }
  form .field-cols .field-col:last-of-type {
    margin-left: 3rem;
    margin-right: 0;
  }
}
form .field-group input[type=text] {
  margin-bottom: 3rem;
}
@media (min-width: 63.9em) {
  form .field-group input[type=text]:last-of-type {
    margin-bottom: 0;
  }
}

@keyframes spread {
  from {
    top: -2rem;
  }
  to {
    top: 0;
  }
}
.carousel {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  /* For the white section */
}
.carousel .carousel-group {
  display: none;
  position: relative;
}
.carousel .carousel-group img {
  position: relative;
  animation: spread 0.4s ease-out;
}
.carousel .carousel-group img:nth-child(1) {
  animation-delay: 0s;
}
.carousel .carousel-group img:nth-child(2) {
  animation-delay: 0.05s;
}
.carousel .carousel-group img:nth-child(3) {
  animation-delay: 0.1s;
}
.carousel .carousel-group img:nth-child(4) {
  animation-delay: 0.15s;
}
.carousel .carousel-group.visible {
  display: block;
}
.carousel img.carousel-group {
  animation: spread 0.4s ease-out;
}
.carousel .carousel-arrow {
  display: inline-block;
  top: 50%;
  left: -3rem;
  font-size: 0;
  line-height: 0;
  transform: scale(0.7);
}
@media (min-width: 63.9em) {
  .carousel .carousel-arrow {
    position: absolute;
    display: inline-block;
    transform: scale(1);
  }
}
.carousel .carousel-arrow:before {
  content: url(../images/arrow.svg);
}
.carousel .carousel-arrow:hover {
  opacity: 0.8;
}
.carousel .carousel-arrow.carousel-arrow-next {
  left: auto;
  right: -3rem;
  transform: scale(0.7) rotate(180deg);
}
@media (min-width: 63.9em) {
  .carousel .carousel-arrow.carousel-arrow-next {
    transform: rotate(180deg);
  }
}
.carousel .carousel-arrow.carousel-arrow-inverted:before {
  content: url(../images/arrow_inverted.svg);
}

@media (min-width: 63.9em) {
  section#intro {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
  section#intro .container {
    flex: 1;
  }
  section#intro .learn-more {
    display: block !important;
  }
}
section#intro .learn-more {
  display: none;
  margin-top: 6rem;
  text-align: center;
  font-weight: bold;
}
section#intro .learn-more a {
  color: white;
  font-size: 2rem;
  text-decoration: none;
}
section#intro .learn-more img {
  display: block;
  margin: 1rem auto 0;
  width: 6rem;
}

section#how {
  padding-bottom: 1px;
}
section#how .cards {
  position: relative;
  display: inline-block;
  margin: 3rem auto 0;
}
@media (min-width: 63.9em) {
  section#how .cards {
    margin-bottom: 3rem;
  }
}
section#how .cards img {
  width: 18rem;
  max-width: 49%;
  transition: all 0.4s ease-in-out;
}
@media (min-width: 63.9em) {
  section#how .cards {
    margin: 6rem auto;
  }
  section#how .cards.closed img:nth-child(1) {
    transform: rotate(0) translateX(150%);
  }
  section#how .cards.closed img:nth-child(2) {
    transform: rotate(-1.25deg) translateX(50%);
  }
  section#how .cards.closed img:nth-child(3) {
    transform: rotate(1.25deg) translateX(-50%);
  }
  section#how .cards.closed img:nth-child(4) {
    transform: rotate(-1.25deg) translateX(-155%);
  }
  section#how .cards img {
    position: relative;
    width: 24rem;
  }
  section#how .cards img:nth-child(1) {
    transform: rotate(-10deg) translateY(4.5rem) translateX(3.5rem);
    z-index: 4;
  }
  section#how .cards img:nth-child(2) {
    transform: rotate(-5deg) translateX(2rem);
    z-index: 3;
  }
  section#how .cards img:nth-child(3) {
    transform: rotate(5deg) translateX(-2rem);
    z-index: 2;
  }
  section#how .cards img:nth-child(4) {
    transform: rotate(10deg) translateY(5rem) translateX(-3.5rem);
    z-index: 1;
  }
}

section#white-cards .cards {
  margin: 3rem auto 0;
}
@media (min-width: 63.9em) {
  section#white-cards .cards {
    margin: 6rem auto;
    padding: 0 3rem;
  }
}
section#white-cards .cards img {
  display: none;
  position: relative;
  width: 18rem;
  max-width: 49%;
}
section#white-cards .cards img:first-child, section#white-cards .cards img.visible {
  display: inline;
}
@media (min-width: 63.9em) {
  section#white-cards .cards img {
    width: 24rem;
    transform: rotate(5deg);
  }
  section#white-cards .cards img:nth-child(1) {
    transform: rotate(-5deg);
    z-index: 1;
  }
}

section#application {
  display: none;
  text-align: center;
}
section#application > h2 {
  margin-bottom: 9rem;
}

footer {
  border-top: 2px solid white;
}
@media (min-width: 63.9em) {
  footer .container {
    display: flex;
  }
  footer nav {
    margin-top: 0 !important;
    text-align: right;
  }
}
footer .logo {
  flex-grow: 1;
  display: block;
  max-width: 17rem;
}
footer .logo img {
  vertical-align: top;
}
footer nav {
  flex-grow: 1;
  flex-basis: 0;
  margin-top: 3rem;
}
footer nav ul {
  list-style: none;
}
footer nav a {
  font-weight: bold;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
}
footer nav a:hover {
  border-color: black;
}

body#thanks {
  background: black;
}
body#thanks h1 {
  font-size: 4rem;
  line-height: 4.7rem;
}
@media (min-width: 63.9em) {
  body#thanks h1 {
    font-size: 4.8rem;
    line-height: 5.6rem;
  }
}
@media (min-width: 63.9em) {
  body#thanks {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  body#thanks > section {
    flex: 1;
  }
}
body#thanks nav li:first-of-type {
  display: none;
}

#closed nav li:first-of-type {
  display: none;
}