// Colors
$red: #FF0000;
$yellow: #FFEC00;
$yellowLight: lighten($yellow, 45%);
$yellowDark: #8E7E07;

// Typography
$font-sans: 'Helvetica Neue', Helvetica, sans-serif;

// Measurements
$content-width: 81rem;

// Responsive
$desktop-width: 63.9em;
