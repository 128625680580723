footer {
  border-top: 2px solid white;

  @include desktop {
    .container {
      display: flex;
    }

    nav {
      margin-top: 0 !important;
      text-align: right;
    }
  }

  .logo {
    flex-grow: 1;
    display: block;
    max-width: 17rem;

    img {
      vertical-align: top;
    }
  }

  nav {
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 3rem;

    ul {
      list-style: none;
    }

    a {
      font-weight: bold;
      color: white;
      text-decoration: none;
      border-bottom: 1px solid white;

      &:hover {
        border-color: black;
      }
    }
  }
}
